<script>
import moment from "moment";
import { required, email, requiredIf, helpers } from "vuelidate/lib/validators";
import Spinner from "vue-simple-spinner";

import { getBeneficiary, createBeneficiary } from "@/state/helpers";
import {
  FETCH_BENEFICIARY_TABLE,
  FETCH_BENEFICIARY_TABLE_MASTER_MERCHANT,
  CREATE_BENEFICIARY,
  UPDATE_BENEFICIARY,
  FETCH_BANKS_LIST,
  FETCH_ALL_PAYMENT_CHANNEL,
  FETCH_MERCHANTS_LIST
} from "@/state/actions.type";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import VuePhoneNumberInput from "vue-phone-number-input";
import { parsePhoneNumber } from 'libphonenumber-js';
import Country from "../../../components/country.vue";
const alphAndSpaceValidator = helpers.regex('alphaAndSpace', /^[a-zA-Z ]*$/);
export default {
  components: {
    Layout,
    Spinner,
    PageHeader,
    Multiselect,
    VuePhoneNumberInput,
    Country
},

  data() {
    return {
      mor_filter: "",
      submitted: false,
      showmodal: false,
      typesubmit: false,
      bankno: "",
      merchants: [],
      phoneNumberVal: null,
      phoneNumber: "",
      isEdit: false,
      chaiBeneficiary: {
        payout_channel: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        bank_account_number: "",
        bank_account_name: "",
        bank_account_middle_name: "",
        bank_account_last_name: "",
        country: "",
        bank_name: "",
        bank_account_type: "",
        bank_no: "",
        fee_payer: "SENDER"
      },
      defaultCountryCode: "",

      fields: [
        { key: "name", sortable: true, tooltip: "views.beneficiary.table.fields.beneficiary_name", label: "views.beneficiary.table.fields.name" },
        {
          key: "bank_accounts.account_number",
          sortable: true,
          label: "views.beneficiary.table.fields.bank_account_number",
          tooltip: "views.beneficiary.table.fields.bank_account_number",
        },
        {
          key: "email",
          sortable: true,
          label: "views.beneficiary.table.fields.email",
          tooltip: "views.beneficiary.table.fields.email",
        },
        {
          key: "phone",
          sortable: true,
          label: "views.beneficiary.table.fields.phone",
          tooltip: "views.beneficiary.table.fields.phone",
        },
        {
          key: "bank_accounts.bank_name",
          sortable: true,
          label: "views.payouts.table.fields.bank_name",
          tooltip: "views.payouts.table.fields.bank_name",
        },
        {
          key: "bank_accounts.account_name",
          sortable: true,
          label: "views.beneficiary.table.fields.bank-acc-name",
          tooltip: "views.beneficiary.table.fields.bank-acc-name",
        },
        {
          key: "bank_accounts.bank_no",
          sortable: true,
          label: "views.beneficiary.table.fields.bank-code",
          tooltip: "views.beneficiary.table.fields.bank-code",
        },
        {
          key: "address",
          sortable: true,
          label: "views.beneficiary.table.fields.address",
          tooltip: "views.beneficiary.table.fields.address",
        },
        {
          key: "action",
          label: "views.payments.routing.action",
          tooltip: "views.payments.routing.action",
          thClass: this.isMasterMerchant ? 'd-none' : '',
          tdClass: this.isMasterMerchant ? 'd-none' : '',
          sortable: true,
          stickyColumn: true,
        },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
    };
  },
  created() {
    if(this.isMasterMerchant){
      this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
        pageIndex: 1,
        pagesize: 10,
        filters: { source: "default" },
      })
      this.fields = this.fields.map((field) => {
        if(field.key === 'action') {
          field.thClass = 'd-none';
          field.tdClass = 'd-none';
        }
        return field;
      });
    } else {
      this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`,{
        country: [],
        paymentMethod: "",
        paymentType: "PAYOUT"
      });
    }
    this.defaultCountryCode = this.$store.state.auth.currentUser.country;
    this.loadData();
  },

  computed: {
    banksList() {
      return this.$store.state.beneficiary.banks_list;
    },

    title() {
      return this.$t("menuitems.payouts.list.beneficiary");
    },
    items() {
      return [
        {
          text: this.$t("menuitems.payouts.list.payouts"),
          active: true
        },
        {
          text: this.$t("menuitems.payouts.list.beneficiary"),
          active: true,
        },
      ];
    },

    getBeneficiaryData() {
      return this.$store.state.beneficiary.content;
    },

    isFetchingTransactions() {
      return this.$store.state.beneficiary.isFetchingData;
    },

    rows() {
      return this.$store.state.beneficiary.total_elements;
    },

    payoutChannels() {
      let channels = this.$store.state.paymentChannels.channels;
      let channelList = []
      for(let psp in channels) {
        if(channels[psp].onboarding_status.status == "Completed") {
          channelList.push(psp)
        }
      }
      return channelList;
      // return Object.keys(this.$store.state.paymentChannels.channels);
    },
    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },
  },

  validations: {
    chaiBeneficiary: {
      payout_channel: { required },
      bank_account_type: { required },
      name: { required },
      email: { email },
      address: { requiredIf: requiredIf(function (nestedModel) {
          return nestedModel.payout_channel === 'PAYNAMICS_PAYOUT';
        })
      },
      bank_account_number: { required },
      bank_account_name: { required,alphAndSpaceValidator },
      bank_account_middle_name: { alphAndSpaceValidator },
      bank_account_last_name: { requiredIf: requiredIf(function (nestedModel) {
          return nestedModel.payout_channel === 'PAYNAMICS_PAYOUT';
        }),
        alphAndSpaceValidator
      },
      country: {
        requiredIf: requiredIf(function (nestedModel) {
          return nestedModel.payout_channel === 'PAYNAMICS_PAYOUT';
        })
      },
      bank_name: { requiredIf: requiredIf(function (nestedModel) {
        return !['PROMPTPAY_NATID', 'PROMPTPAY_MOBILE'].includes(nestedModel.bank_account_type)
      })},
      bank_no: { requiredIf: requiredIf(function (nestedModel) {
        return !['PROMPTPAY_NATID', 'PROMPTPAY_MOBILE'].includes(nestedModel.payout_channel === 'PAYNAMICS_PAYOUT')
      })},
      phone: { },
      fee_payer: { required }
    },
  },

  watch: {
    'chaiBeneficiary.payout_channel': function(value) {
      if(value) {
        this.$store.dispatch(`beneficiary/${FETCH_BANKS_LIST}`, value);
      }
      if(value === 'GBPRIMEPAY_PAYOUT' || value === 'PAYNAMICS_PAYOUT') {
        this.chaiBeneficiary.bank_account_type = 'BANK';
      }
    },
    phoneNumberVal: function (val) {
      this.chaiBeneficiary.phone = (val && val.countryCallingCode && val.phoneNumber) ? `+${val.countryCallingCode}${val.phoneNumber.replace(/ /g,'')}` : "";
    },
  },

  methods: {
    ...getBeneficiary,
    ...createBeneficiary,

    selectbankcode(){
      this.chaiBeneficiary.bank_no = event.target.options[event.target.selectedIndex].dataset.key;
    },

    loadData() {
      let payload = {
        page: this.currentPage,
        pageIndex: this.currentPage,
        pagesize: this.perPage
      }

      if(this.isMasterMerchant) {
        payload.filters = {
          merchants: this.merchants.map(merchant => merchant.email_id),
        }

        if(this.mor_filter) {
          payload.filters.common = this.mor_filter;
        }

        this.$store.dispatch(`beneficiary/${FETCH_BENEFICIARY_TABLE_MASTER_MERCHANT}`, payload);
      } else {

        if(this.mor_filter) {
          payload.filter = this.mor_filter;
        }

        this.$store.dispatch(`beneficiary/${FETCH_BENEFICIARY_TABLE}`, payload);
      }
    },

    addVendors() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid || (this.chaiBeneficiary.phone && this.phoneNumberVal && !this.phoneNumberVal.isValid)) {
        return;
      }

      const payload = {
        payout_channel: this.chaiBeneficiary.payout_channel,
        name: this.chaiBeneficiary.name,
        email: this.chaiBeneficiary.email,
        phone: this.chaiBeneficiary.phone,
        address: this.chaiBeneficiary.address,
        bank_account_number: this.chaiBeneficiary.bank_account_number,
        bank_account_name: this.chaiBeneficiary.bank_account_name,
        bank_name: this.chaiBeneficiary.bank_name,
        bank_account_middle_name: this.chaiBeneficiary.bank_account_middle_name,
        bank_account_last_name: this.chaiBeneficiary.bank_account_last_name,
        country: this.chaiBeneficiary.country,
        bank_no: this.chaiBeneficiary.bank_no,
        bank_account_type: this.chaiBeneficiary.bank_account_type,
        fee_payer: this.chaiBeneficiary.payout_channel == 'APPOTAPAY_PAYOUT' ? this.chaiBeneficiary.fee_payer : "SENDER",
      };

      const createOrEdit = this.isEdit ? `beneficiary/${UPDATE_BENEFICIARY}` : `beneficiary/${CREATE_BENEFICIARY}`;

      this.$store.dispatch(createOrEdit, payload)
      .then((result) => {
        this.$notify({
          type: "success",
          text: result.message,
          closeOnClick: true,
        });
        setTimeout(() => {
          this.loadData();
        }, 500);

        this.submitted = false;
        this.showmodal = false;
        this.resetBeneficiary();
        this.$v.$reset();
      })
      .catch((error) => {
        this.$notify({
          type: "error",
          text: error.message,
          closeOnClick: true,
        });

      });
    },

    hideModal() {
      this.submitted = false;
      this.showmodal = false;
      this.resetBeneficiary();
    },

    openVendorsModal() {
      this.submitted = false;
      this.resetBeneficiary();

      if(this.payoutChannels.length == 1) {
        this.chaiBeneficiary.payout_channel = this.payoutChannels[0];
      }

      this.showmodal = true;
    },

    resetBeneficiary() {
      this.chaiBeneficiary = {
        payout_channel: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        bank_account_number: "",
        bank_account_name: "",
        bank_account_middle_name: "",
        bank_account_last_name: "",
        country: "",
        bank_name: "",
        bank_account_type: "",
        bank_no: "",
        fee_payer: "SENDER"
      };
      this.phoneNumberVal = null;
      this.phoneNumber = "";
      this.isEdit = false;
    },

    filterTransactionByMor() {
      if (this.mor_filter == "") {
        this.loadData();
      } else {
        this.loadData();
      }
    },

    fetchTransactionOnEmptyFilter() {
      if (this.mor_filter == "") {
        this.loadData();
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.chaiBeneficiary[name];
      return $dirty ? !$error : null;
    },
    accNumberLabel() {
      if(this.chaiBeneficiary.payout_channel === 'BBL_PAYOUT') {
        if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_NATID') {
          return 'Promptpay National ID';
        } else if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_MOBILE'){
          return 'Promptpay Mobile number';
        }
      }
      return this.chaiBeneficiary.bank_account_type == 'CARD' ? this.$t("views.payouts.card-no") : this.$t("views.payouts.bank-acc-no")
    },
    accNumberError() {
      if(this.chaiBeneficiary.payout_channel === 'BBL_PAYOUT') {
        if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_NATID') {
          return 'Benefactor Promptpay National ID is required';
        } else if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_MOBILE'){
          return 'Benefactor Promptpay Mobile number is required';
        }
      }
      return this.chaiBeneficiary.bank_account_type == 'CARD' ? this.$t("views.payouts.required_field_msg.card-no") : this.$t("views.payouts.required_field_msg.bank-acc-no");
    },
    accNameLabel() {
      if(this.chaiBeneficiary.payout_channel === 'BBL_PAYOUT') {
        if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_NATID') {
          return 'Promptpay National ID Name';
        } else if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_MOBILE'){
          return 'Promptpay Account Name';
        }
      } else if(this.chaiBeneficiary.payout_channel === 'PAYNAMICS_PAYOUT') {
        return 'Bank Account First Name';
      }
      return this.chaiBeneficiary.bank_account_type == 'CARD' ? this.$t("views.payouts.card_holder_name") : this.$t("views.payouts.bank-acc-name")
    },
    accNameError() {
      if(this.chaiBeneficiary.payout_channel === 'BBL_PAYOUT') {
        if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_NATID') {
          return 'Benefactor Promptpay National ID Name is required';
        } else if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_MOBILE'){
          return 'Benefactor Promptpay Account Name is required';
        }
      } else if(this.chaiBeneficiary.payout_channel === 'PAYNAMICS_PAYOUT') {
        return 'Bank Account First Name is required';
      }
      return this.chaiBeneficiary.bank_account_type == 'CARD' ? this.$t("views.payouts.required_field_msg.card_holder_name") : this.$t("views.payouts.required_field_msg.bank_acc_name");
    },
    merchantSelected() {
      this.loadData();
    },
    countryChanged(value) {
      this.chaiBeneficiary.country = value;
    },
    editBeneficiary(item) {
      if(this.isMasterMerchant) {
        return;
      }
      this.chaiBeneficiary = {
        payout_channel: item.payout_channel,
        name: item.name,
        email: item.email,
        address: item.address,
        bank_account_number: item.bank_accounts.account_number,
        bank_account_name: item.bank_accounts.account_name,
        bank_account_middle_name: item.bank_accounts.account_middle_name,
        bank_account_last_name: item.bank_accounts.account_last_name,
        country: item.bank_accounts.country,
        bank_name: item.bank_accounts.bank_name,
        bank_account_type: item.bank_accounts.account_type,
        bank_no: item.bank_accounts.bank_no,
        fee_payer: item.fee_payer
      };

      this.phoneNumber = item.phone;

      if(item.phone) {
        const phoneNumber = parsePhoneNumber(item.phone, this.defaultCountryCode);
        if(phoneNumber) {
          this.defaultCountryCode = phoneNumber.country;
          this.phoneNumberVal = {
            countryCallingCode: phoneNumber.countryCallingCode,
            countryCode: phoneNumber.country,
            formatInternational: phoneNumber.formatInternational(),
            formatNational: phoneNumber.formatNational(),
            formattedNumber: phoneNumber.number,
            isValid: phoneNumber.isValid(),
            nationalNumber: phoneNumber.nationalNumber,
            phoneNumber: phoneNumber.number
          }
        }
      }

      this.isEdit = true;
      this.showmodal = true;
    },
    closeModal() {
      this.$v.$reset();
      this.showmodal = false;
    }
  },

  filters: {
    formateDate(value) {
      return moment(value).format("MMMM DD YYYY, h:mm:ss a");
    },
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <multiselect
          v-if="isMasterMerchant"
          v-model="merchants"
          :options="merchantListDataFromStore"
          @input="merchantSelected"
          placeholder="Search Merchants"
          track-by="email_id"
          label="email_id"
          class="sm-multiselect col-md-3 ml-auto"
          :searchable="true"
          :multiple="true"
          :taggable="true"
        ></multiselect>
        <div class="d-flex flex-row justify-content-between my-4">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              <span class="text-nowrap">{{ $t('common.show') }}</span>&nbsp;
              <b-form-select
                v-model="perPage"
                @change="loadData"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;<span class="text-nowrap">{{ $t('common.entries') }}</span>
            </label>
          </div>
          <div class="d-flex justify-content-end w-50">
            <div class="flex-fill mr-2">
              <b-input-group>
                <b-form-input
                  type="search"
                  v-model="mor_filter"
                  @keyup="loadData"
                  @keyup.enter="loadData"
                  class="form-control-sm"
                  placeholder="Search for references or customer details"
                ></b-form-input>
                <b-input-group-append>
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="loadData"
                  >
                    {{ $t("button.search") }}
                  </button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div v-if="!isMasterMerchant">
              <button
                class="btn btn-primary btn-sm"
                @click="openVendorsModal"
              >
                {{ $t("views.payouts.create-beneficiary") }}
              </button>
            </div>
          </div>
          <div class="col-sm-12 col-md-4" style="display: none">
            <b-input-group>
              <b-form-input
                type="search"
                v-model="mor_filter"
                @keyup="fetchTransactionOnEmptyFilter"
                @keyup.enter="filterTransactionByMor"
                class="form-control form-control-sm ml-2"
                :placeholder="$t('views.payouts.search_vendors')"
              ></b-form-input>
              <b-input-group-append>
                <button
                  class="btn btn-primary btn-block btn-sm"
                  @click="filterTransactionByMor"
                >
                  {{ $t("button.search") }}
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="">
          <b-table
            id="my-table"
            class="table-centered text-center table-custom"
            :items="getBeneficiaryData"
            :fields="fields"
            responsive="lg"
            :per-page="perPage"
            :bordered="false"
            sticky-header="500px"
            show-empty
            :empty-text="$t('common.no-records')"
            busy.sync="true"
            hover
          >
            <template #cell(account_number)="data">
              <router-link
                :to="'/payouts/vendor-accounts?uuid=' + data.item.uuid"
                class="card-link text-decoration-underline"
              >
                {{ $t("views.payouts.view-details") }}
              </router-link>
            </template>

            <template v-slot:head()="data">
              <span v-b-tooltip.hover.top :title="$t(data.field.tooltip)">
                {{ $t(data.label) }}
              </span>
            </template>
            <template v-slot:cell(action)="{ item }">
              <b-btn
                variant="light"
                size="sm"
                class="mr-2"
                @click="editBeneficiary(item)"
                v-if="!isMasterMerchant"
              >Edit</b-btn
              >
            </template>
          </b-table>
          <Spinner v-if="isFetchingTransactions === true"></Spinner>
        </div>

        <div class="row">
          <div class="col">
            <div
              class="dataTables_paginate paging_simple_numbers float-right"
            >
              <ul class="pagination pagination-rounded">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  @input="loadData"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      size="lg"
      v-model="showmodal"
      v-if="!isMasterMerchant"
      :title="isEdit ? 'Update Beneficiary' : $t('views.payouts.create-beneficiary')"
      title-class="text-dark font-18"
      no-close-on-backdrop
      hide-footer
    >
      <form @submit.prevent="addVendors">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <i class="mdi mdi-account"></i>

              <label for="select_psp">
                {{ $t("common.psp") }}
                <span class="text-danger mr-1">*</span>
              </label>

              <b-form-select
                name="select_psp"
                v-model="chaiBeneficiary.payout_channel"
                :options="payoutChannels"
                :disabled="isEdit"
                :class="{
                  'is-invalid': submitted && $v.chaiBeneficiary.payout_channel.$error,
                }"
              >
                <template #first>
                  <b-form-select-option :value="null">-- Please select an option --</b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="submitted && !$v.chaiBeneficiary.payout_channel.required"
                class="invalid-feedback"
              >
                {{ $t("views.payouts.required_field_msg.psp") }}
              </div>
              <div
                v-if="payoutChannels.length == 0"
                class="text-danger"
              >
                Please configure a Payout Channel at <a href="/settings/payout-channel">Payout Channels section</a> or Kindly contact business team of PortOne for more details.
              </div>
            </div>
          </div>

          <div class="col-md-6" v-if="chaiBeneficiary.payout_channel === 'PAYNAMICS_PAYOUT'">
            <div class="form-group">
              <label for="country">Country</label>
              <Country name="country" :country="chaiBeneficiary.country" @country-changed="countryChanged" :invalid="submitted && $v.chaiBeneficiary.country.$error"/>
              <div
                v-if="submitted && $v.chaiBeneficiary.country.$error"
                style="margin-top: 0.25rem; font-size: 80%; color: #ff3d60;"
              >
                Country is required
              </div>
            </div>
          </div>

          <div class="col-md-6" v-if="chaiBeneficiary.payout_channel !== 'GBPRIMEPAY_PAYOUT'">
            <div class="form-group">
              <i class="mdi mdi-account"></i>

              <label for="bank_account_type">
                {{ $t("views.payouts.bank_account_type") }}
                <span class="text-danger mr-1">*</span>
              </label>

              <b-form-select
                name="bank_account_type"
                v-model="chaiBeneficiary.bank_account_type"
                :disabled="isEdit || chaiBeneficiary.payout_channel === 'PAYNAMICS_PAYOUT'"
                :class="{
                  'is-invalid': submitted && $v.chaiBeneficiary.bank_account_type.$error,
                }">
                <b-form-select-option :value="null">{{ $t('common.select_option') }}</b-form-select-option>
                <b-form-select-option value="BANK">Bank Account</b-form-select-option>
                <b-form-select-option v-if="chaiBeneficiary.payout_channel !== 'BBL_PAYOUT'" value="CARD">Card</b-form-select-option>
                <b-form-select-option v-if="chaiBeneficiary.payout_channel === 'BBL_PAYOUT'" value="PROMPTPAY_NATID">Promptpay - National ID</b-form-select-option>
                <b-form-select-option v-if="chaiBeneficiary.payout_channel === 'BBL_PAYOUT'" value="PROMPTPAY_MOBILE">Promptpay - Mobile number</b-form-select-option>
              </b-form-select>
              <div
                v-if="submitted && !$v.chaiBeneficiary.bank_account_type.required"
                class="invalid-feedback"
              >
                {{ $t('views.payouts.required_field_msg.bank_account_type') }}
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <i class="mdi mdi-account"></i>

              <label for="name">
                {{ $t("views.payouts.name") }}
                <span class="text-danger mr-1">*</span>
              </label>

              <input
                id="name"
                v-model="chaiBeneficiary.name"
                type="text"
                class="form-control"
                placeholder="John Doe"
                :class="{
                  'is-invalid': submitted && $v.chaiBeneficiary.name.$error,
                }"
              />
              <div
                v-if="submitted && !$v.chaiBeneficiary.name.required"
                class="invalid-feedback"
              >
                {{ $t("views.payouts.required_field_msg.name") }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="email">
                <i class="mdi mdi-email-outline"></i>

                {{ $t("views.payouts.email") }}
                <!-- <span class="text-danger mr-1">*</span> -->
              </label>
              <input
                type="email"
                v-model="chaiBeneficiary.email"
                class="form-control"
                placeholder="xyz@mycompanysite.com"
                :class="{
                  'is-invalid': submitted && $v.chaiBeneficiary.email.$error,
                }"
              />
              <!-- <div
                v-if="submitted && !$v.chaiBeneficiary.email.required"
                class="invalid-feedback"
              >
                Email is required
              </div> -->
              <div
                v-if="submitted && !$v.chaiBeneficiary.email.email"
                class="invalid-feedback"
              >
                {{ $t("views.payouts.required_field_msg.email") }}
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="phone">
                <i class="mdi mdi-phone"></i>

                {{ $t("views.payouts.phone") }}
              </label>
              <VuePhoneNumberInput
                v-model="phoneNumber"
                id="VuePhoneNumber"
                @update="phoneNumberVal = $event"
                valid-color="#1cbb8c"
                :default-country-code="defaultCountryCode"
                :class="(submitted && $v.chaiBeneficiary.phone.$error) || (submitted && chaiBeneficiary.phone && phoneNumberVal && !phoneNumberVal.isValid) ? `border border-danger rounded` : ``"
                :preferred-countries="['VN', 'TH', 'ID', 'SG', 'KR', 'PH', 'IN', 'MY']"
              />
              <div
                v-if="submitted && $v.chaiBeneficiary.phone.$error"
                class="text-danger font-size-12"
              >
                {{ $t("views.payouts.required_field_msg.phone") }}
              </div>
              <div
                v-else-if="submitted && chaiBeneficiary.phone && phoneNumberVal && !phoneNumberVal.isValid"
                class="text-danger font-size-12"
              >
                Invalid Phone Number.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="address">
                <i class="mdi mdi-home-map-marker"></i>
                {{ $t("views.payouts.address") }}
                <span class="text-danger mr-1" v-if="chaiBeneficiary.payout_channel === 'PAYNAMICS_PAYOUT'">*</span>
              </label>
              <input
                v-model="chaiBeneficiary.address"
                type="tel"
                class="form-control"
                :placeholder="$t('views.payouts.address')"
                :class="{
                  'is-invalid': submitted && $v.chaiBeneficiary.address.$error,
                }"
              />
              <div
                v-if="submitted && !$v.chaiBeneficiary.address.required"
                class="invalid-feedback"
              >
                Address is required
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="bankAccountNumber">
                <i class="mdi mdi-bank"></i>

                {{ accNumberLabel() }}
                <span class="text-danger mr-1">*</span>
              </label>
              <b-form-input
                v-model="$v.chaiBeneficiary.bank_account_number.$model"
                id="bankAccountNumber"
                type="number"
                :placeholder="accNumberLabel()"
                :state='validateState("bank_account_number")'
                :disabled="isEdit"
                :readonly="isEdit"
                :class="{
                  'is-invalid':
                    submitted && $v.chaiBeneficiary.bank_account_number.$error,
                }"
              />
              <div
                v-if="
                  submitted && $v.chaiBeneficiary.bank_account_number.$error
                "
                class="invalid-feedback"
              >
                <span v-if="!$v.chaiBeneficiary.bank_account_number.required">
                  {{ accNumberError() }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="bankAccountName">
                <i class="mdi mdi-account-circle"></i>
                {{ accNameLabel() }}
                <span class="text-danger mr-1">*</span>
              </label>
              <b-form-input
                id="bankAccountName"
                type="text"
                :disabled="isEdit"
                :readonly="isEdit"
                :placeholder="accNameLabel()"
                v-model="$v.chaiBeneficiary.bank_account_name.$model"
                :class="{
                  'is-invalid':
                    submitted && $v.chaiBeneficiary.bank_account_name.$error,
                }"
              />
              <div
                v-if="
                  submitted && $v.chaiBeneficiary.bank_account_name.$error
                "
                class="invalid-feedback"
              >
                <span v-if="!$v.chaiBeneficiary.bank_account_name.required">
                  {{ accNameError() }}
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-6" v-if="chaiBeneficiary.payout_channel === 'PAYNAMICS_PAYOUT'">
            <div class="form-group">
              <label for="bankAccountMiddleName">
                <i class="mdi mdi-account-circle"></i>
                Bank Account Middle Name
              </label>
              <b-form-input
                id="bankAccountMiddleName"
                type="text"
                class="form-control"
                :disabled="isEdit"
                :readonly="isEdit"
                placeholder="Bank Account Middle Name"
                v-model="$v.chaiBeneficiary.bank_account_middle_name.$model"
                :class="{
                  'is-invalid':
                    submitted && $v.chaiBeneficiary.bank_account_middle_name.$error,
                }"
              />
              <div
                v-if="
                  submitted && $v.chaiBeneficiary.bank_account_middle_name.$error
                "
                class="invalid-feedback"
              >
                Invalid bank account middle name
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="chaiBeneficiary.payout_channel === 'PAYNAMICS_PAYOUT'">
            <div class="form-group">
              <label for="bankAccountLastName">
                <i class="mdi mdi-account-circle"></i>
                  Bank Account Last Name
                <span class="text-danger mr-1">*</span>
              </label>
              <b-form-input
                id="bankAccountLastName"
                type="text"
                class="form-control"
                :disabled="isEdit"
                :readonly="isEdit"
                placeholder="Bank Account Last Name"
                v-model="$v.chaiBeneficiary.bank_account_last_name.$model"
                :class="{
                  'is-invalid':
                    submitted && $v.chaiBeneficiary.bank_account_last_name.$error,
                }"
              />
              <div
                v-if="
                  submitted && $v.chaiBeneficiary.bank_account_last_name.$error
                "
                class="invalid-feedback"
              >
                <span v-if="!$v.chaiBeneficiary.bank_account_last_name.required">
                  Bank account last name is required
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-6" v-if="!['PROMPTPAY_NATID', 'PROMPTPAY_MOBILE'].includes(chaiBeneficiary.bank_account_type)">
            <div class="form-group">
              <label for="bankName">
                <i class="mdi mdi-account"></i>

                {{ $t("views.payouts.bank-name") }}
                <span class="text-danger mr-1">*</span>
              </label>
              <select
                id="bankName"
                name="bankName"
                class="form-control"
                :placeholder="$t('views.payouts.bank-name')"
                v-model="chaiBeneficiary.bank_name"
                :disabled="isEdit"
                @change="selectbankcode"
                :class="{
                  'is-invalid':
                    submitted && $v.chaiBeneficiary.bank_name.$error,
                }"
              >

              <option :key="index" v-for="(option, index) in banksList" :data-key="option.bank_no" :value="option.bank_name" >{{ option.bank_name }}</option>
              </select>
              <div
                v-if="submitted && !$v.chaiBeneficiary.bank_name.required"
                class="invalid-feedback"
              >
              {{ $t("views.payouts.required_field_msg.bank_name") }}
              </div>
            </div>
          </div>

          <div class="col-md-6" v-if="!['PROMPTPAY_NATID', 'PROMPTPAY_MOBILE'].includes(chaiBeneficiary.bank_account_type)">
            <div class="form-group">
              <label for="bankNumber">
                <i class="mdi mdi-account"></i>

                {{ $t("views.payouts.bank-number") }}
                <span class="text-danger mr-1">*</span>
              </label>
              <input
                id="bankNumber"
                type="text"
                class="form-control"
                :disabled="isEdit"
                :readonly="isEdit"
                :placeholder="$t('views.payouts.bank-number')"
                v-model="chaiBeneficiary.bank_no"
                :class="{
                  'is-invalid': submitted && $v.chaiBeneficiary.bank_no.$error,
                }"
              />
              <div
                v-if="submitted && !$v.chaiBeneficiary.bank_no.required"
                class="invalid-feedback"
              >
              {{ $t("views.payouts.required_field_msg.bank_no") }}
              </div>
            </div>
          </div>

          <div class="col-md-6" v-if="chaiBeneficiary.payout_channel == 'APPOTAPAY_PAYOUT'">
            <div class="form-group">
              <label for="fee_payer">
                <i class="mdi mdi-account-circle"></i>
                {{ $t("views.payouts.fee_payer") }}
                <span class="text-danger mr-1">*</span>
              </label>
              <b-form-select
                name="fee_payer"
                v-model="chaiBeneficiary.fee_payer"
                :class="{
                  'is-invalid': submitted && $v.chaiBeneficiary.fee_payer.$error,
                }">
                <b-form-select-option :value="null">{{ $t("common.select_option") }}</b-form-select-option>
                <b-form-select-option value="SENDER">SENDER</b-form-select-option>
                <b-form-select-option value="RECEIVER">RECEIVER</b-form-select-option>
              </b-form-select>

              <div
                v-if="submitted && !$v.chaiBeneficiary.fee_payer.required"
                class="invalid-feedback"
              >
                {{ $t("views.payouts.required_field_msg.fee_payer") }}
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <b-button
            variant="light"
            class="btn mr-1"
            @click="closeModal"
            >{{
              $t("button.close")
            }}</b-button
          >
          <button type="submit" class="btn btn-primary ml-1">
            {{ $t("button.save") }}
          </button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .b-table-sticky-column:last-child {
  right: 0;
}
:disabled {
  background: #f3f6f9;
  color: #9da0af;
}
.btn{
  border-radius: 4px;
}
.form-control{
  font-size: 14px;
}
label{
  font-size: 14px;
}
</style>
